<template>
  <div class="individual-central-wrapper">
    <el-card>
      <el-form :model="individualInfo">
        <el-form-item label="设备编号">
          <el-input v-model="individualInfo.id"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button icon="">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'IndividualCentral',
  data() {
    return {
      individualInfo: {
        id: '', //单兵设备ID
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.individual-central-wrapper {
  width: 100%;
}
</style>
